<template>
  <div>
    <Loading v-show="show" />

    <div class="breadcrumb-banner-area">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="breadcrumb-text">
              <h1 class="text-left">{{ Activity }}</h1>
              <div class="breadcrumb-bar">
                <ul class="breadcrumb text-left">
                  <li>
                    <router-link href="#" aria-label="title" to="/">{{
                      home
                    }}</router-link>
                  </li>
                  <li>{{ Activity }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Events Details Area Start-->
    <div class="news-details-area section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-12 col-12">
            <div class="news-details-content">
              <div class="single-latest-item">
                <img loading="lazy" alt="" v-bind:src="imgUniv" />
                <div class="single-latest-text">
                  <h3 id="TitleUniv"></h3>
                  <p id="DetailsUniv"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-12">
            <div class="sidebar-widget">
              <div class="single-sidebar-widget">
                <h4 class="title">الانشطة</h4>
                <div class="recent-content">
                  <div
                    v-for="Events in Events"
                    :key="Events.postId"
                    class="recent-content-item"
                  >
                    <router-link
                      href="#"
                      aria-label="title"
                      :to="{
                        name: 'Event-with-id-And-Type',
                        params: {
                          type: $route.params.type,
                          id: Events.postID,
                        },
                      }"
                      ><img
                        loading="lazy"
                        alt=""
                        @click="
                          getDataEvents(Events.postID, $route.params.type)
                        "
                        class="img_left"
                        v-bind:src="
                          'https://api2.yuniv.net:444/images/post/' +
                          Events.postImage
                        "
                      />
                    </router-link>
                    <div class="recent-text">
                      <p
                        @click="
                          getDataEvents(Events.postID, $route.params.type)
                        "
                      >
                        <router-link
                          href="#"
                          aria-label="title"
                          :to="{
                            name: 'Event-with-id-And-Type',
                            params: {
                              type: $route.params.type,
                              id: Events.postID,
                            },
                          }"
                        >
                          {{ Events.postTitle }}
                        </router-link>

                        <!--   <router-link
                class="button-default"
                :to="{
                  name: 'Event-with-id-And-Type',
                  params: {
                    brn: $route.params.id,
                    name: 'الاقسام',  
                    type: 'Univ.Faculty.Activity',
                    id: event.postID,
                  },
                }"
              > -->
                      </p>
                      <!-- <p>   {{ Events.postSubTitle }}</p> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="single-sidebar-widget">
                <ul class="tags">
                  <li>
                    <router-link
                      href="#"
                      aria-label="title"
                      :to="{
                        name: 'Events-With-Type',
                        params: {
                          type: $route.params.type,
                        },
                      }"
                    >
                      {{ BtnMore }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End of Events Details Area-->
  </div>
</template>
<script>
import Loading from "./Loading";

import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      show: true,
      Events: [],
      Title: "",
      Details: "",
      imgUniv: "",
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      home: "",
      Activity: "",
      BtnMore: "",
    };
  },
  methods: {
    getDataEvents(PostID, Type) {
      var self = this;
      self.show = true;
      window.scroll(0, 0);
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        self.translate = self.EnTranslat;
      }
      self.Activity = self.translate[0]["Home"]["Activity"];
      self.home = self.translate[0]["HeaderAndFooter"]["Home"];
      self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
      var bodyFormData1 = new FormData();
      bodyFormData1.append("check", "getPostById");
      bodyFormData1.append("PostId", PostID);
      bodyFormData1.append("Lang", localStorage.getItem("lang"));
      bodyFormData1.append("FbrnId", "U");
      bodyFormData1.append("Type", Type);
      bodyFormData1.append("ParentId", "NTROOT0");
      bodyFormData1.append("PostName", "");
      bodyFormData1.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData1,
      })
        .then(function (response) {
          var arr = response.data;
          if (arr.length != 0) {
            self.imgUniv =
              "https://api2.yuniv.net:444/images/post/" +
              response.data[0]["postImage"];
            document.getElementById("TitleUniv").innerText =
              response.data[0]["postTitle"];
            document.getElementById("DetailsUniv").innerHTML =
              response.data[0]["postDetails"];
          }
          self.show = false;
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
          self.show = false;
        });
    },
    getApis() {
      var self = this;
      self.getDataEvents(this.$route.params.id, this.$route.params.type);
      var bodyFormData2 = new FormData();
      bodyFormData2.append("check", "getPost");
      bodyFormData2.append("Lang", localStorage.getItem("lang"));
      bodyFormData2.append("PostId", "");
      bodyFormData2.append("FbrnId", "U");
      bodyFormData2.append("Type", this.$route.params.type);
      bodyFormData2.append("ParentId", "NTROOT0");
      bodyFormData2.append("PostName", "");
      bodyFormData2.append("Pno", "6");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData2,
      })
        .then(function (response) {
          self.Events = response.data;

          document.getElementById("VueMainJsNewTouch")?.remove();
          let recaptchaScript = document.createElement("script");
          recaptchaScript.setAttribute("src", "/js/main.js");
          recaptchaScript.id = "VueMainJsNewTouch";
          document.head.appendChild(recaptchaScript);
          setTimeout(() => {
            self.show = false;
            document.querySelectorAll("div.loader").forEach(function (element) {
              element.setAttribute("hidden", true);
            });
          }, 100);
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
        });
    },
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      })
        .then(function (response) {
          self.lang = response.data[0]["LangId"];
          localStorage.setItem("lang", response.data[0]["LangId"]);
          if (response.data[0]["LangId"] == "Ar") {
            self.translate = self.ArTranslat;
            document.getElementById("langfilewebsites")?.remove();
            self.getApis();
          } else {
            self.translate = self.EnTranslat;
            let link1 = document.createElement("link");
            link1.setAttribute("rel", "stylesheet");
            link1.id = "langfilewebsites";
            link1.setAttribute("href", "../../../style-ltr.css");
            document.head.appendChild(link1);
            self.getApis();
          }
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
        });
    } else {
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
        document.getElementById("langfilewebsites")?.remove();
        self.getApis();
      } else {
        self.translate = self.EnTranslat;
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "../../../style-ltr.css");
        document.head.appendChild(link1);
        self.getApis();
      }
    }
    self.Activity = self.translate[0]["Home"]["Activity"];
    self.home = self.translate[0]["HeaderAndFooter"]["Home"];
    self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
  },
};
</script>

<style scoped>
.title {
  width: 100% !important;
  text-align: right !important ;
  background: #fff !important ;
  position: relative !important ;
}
.single-sidebar-widget .tags li a {
  background: #0c3ef7 none repeat scroll 100% 0;
  border: 1px solid #0c3ef7;
  color: #ffffff;
  float: right;
  margin-bottom: 13px;
  margin-left: 6px;
  padding: 11px 35px;
  position: absolute;
}
.img_left {
  height: 70px !important;
  widows: 70px im !important;
}
.recent-text p {
  font-size: 15px;
  line-height: 23px;
}
.single-latest-item img {
  width: 870px;
  height: 435px;
}
.news-details-content .single-latest-text {
  padding: 28px 20px 0px;
  text-align: justify;
}
</style>
